import {Alert, Modal, Size, Textfield, Typography} from '@objectedge/pecai-storefront-ds';
import {useState} from 'react';
import {getMediaPath} from '~/utils/getPublicPath';
import styles from './CouponBoschDiscountModal.module.scss';

type couponBoschDiscountModalProps = {
  isOpen: boolean;
  cancelHandler: () => void;
  couponString: string;
}

const CouponBoschDiscountModal: React.FC<couponBoschDiscountModalProps> = ({ isOpen, cancelHandler, couponString }) => {
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const copyIcon = getMediaPath('/media/home/copyIcon.svg');

  const handleCopyTextFieldValue = (text: string) => {
    navigator.clipboard.writeText(text);
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 3000);
  }

  return (
    <Modal
      isOpen={isOpen}
      cancelHandler={cancelHandler}
      okHandler={() => null}
      showOkButton={false}
      showModalHeader={true}
      showCancelButton={false}
      size="lg"
      contentStyle={styles['main']}
      modalProps={{centered: true}}
    >
      <div className={styles['paragraphFormat']}>
        <Typography variant="display-4" mobileClass="button-large">
          Olá <Typography variant="display-4" mobileClass="button-large" color="primary" weight="bold">Bosch Car Service </Typography>
          seja bem vindo ao Peça Aí! Você tem um <Typography variant="display-4" mobileClass="button-large" color="default" weight="bold">cupom de desconto exclusivo</Typography> para utilização em todas as compras.
        </Typography>
      </div>
      <div className={`${styles['paragraphFormat']} ${styles['noMarginBottom']}`}>
        <Typography variant="display-4" mobileClass="button-large">
          <Typography variant="display-4" mobileClass="button-large" color="primary" weight="bold">13%&nbsp;
          </Typography>
          em&nbsp;<Typography variant="display-4" mobileClass="button-large" color="default" weight="bold">Produtos BOSCH</Typography>
        </Typography>
      </div>
      <div className={`${styles['paragraphFormat']} ${styles['noMarginBottom']}`}>
        <Typography variant="display-4" mobileClass="button-large" color="primary" weight="bold">
          &amp;
        </Typography>
      </div>
      <div className={styles['paragraphFormat']}>
        <Typography variant="display-4" mobileClass="button-large">
          <Typography variant="display-4" mobileClass="button-large" color="primary" weight="bold">8%&nbsp;
          </Typography>
          nas&nbsp;<Typography variant="display-4" mobileClass="button-large" color="default" weight="bold">demais&nbsp;</Typography>
          marcas
        </Typography>
      </div>
      <div className={styles['inputCouponStringCopy']}>
        <Typography variant="lead" mobileClass="button-large" color="mid-grey" weight="bold">
          Aplique o código na tela final de compra
        </Typography>
        <Textfield
          value={couponString}
          icon={
            <img
              id="copyCoupon"
              src={copyIcon}
              alt="Ícone de cópia de cupom"
            />
          }
          iconEvent={() => handleCopyTextFieldValue(couponString)}
          className={styles['couponInput']}
        />
        <Alert
          isOpen={alertVisible}
          color="success"
          className={styles['successAlert']}
          size={Size.MD}
          fixed
        >
          Cupom copiado com sucesso!
        </Alert>
      </div>
    </Modal>
  );
};

export {CouponBoschDiscountModal};


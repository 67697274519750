import { useReactiveVar } from '@apollo/client';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import { CouponBoschButton, CouponBoschDiscountModal } from '~/components/profile/CouponBoschDiscountModal';
import { Footer, Header } from '~/components/structure';
import { userLoggedIn } from '~/state/user';
import { Page, useNavigation } from '~/utils/navigation';
import { useAlerts } from '~/utils/useAlerts';
import styles from './MainLayout.module.scss';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';
import clsx from 'clsx';
import { ClarityTag } from '~/utils/ClarityTag';
interface MainLayoutProps {
  /** Allow access to a page using this interface w/o being logged in */
  unsecure?: Boolean;
  clipContent?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, unsecure = false, clipContent = false }) => {
  const { provider, token, isLoggedIn } = useContext(AuthenticationContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const userJustLoggedIn = useReactiveVar(userLoggedIn);

  const providerHasBoschCarServiceBuyersGroup = !!provider?.buyersGroups?.find((item) => item === 'bosch_car_service');

  const { goToPage } = useNavigation();
  const router = useRouter();
  const { actions } = useAlerts();

  const { handleGoToLoginModal } = useLoginAndRegisterModal();

  useEffect(() => {
    if (isLoggedIn && providerHasBoschCarServiceBuyersGroup && userJustLoggedIn) {
      setIsModalOpen(true);
      userLoggedIn(false);
    }
  }, [isLoggedIn, providerHasBoschCarServiceBuyersGroup, userJustLoggedIn]);

  useEffect(() => {
    if (provider && !provider.isBToBEnabled && !unsecure) {
      actions.addDangerAlert(
        'Você ainda não tem acesso a está área. Por favor, entre em contato com a nossa equipe de atendimento. Você encontra essas informações em peca.ai/ajuda'
      );
      goToPage(Page.home);
      return;
    }
  }, [router?.pathname, provider]);
  // If user is not logged (or it's not an unsecure page), move user to login page
  useEffect(() => {
    if (process.browser && !token && !unsecure && router?.isReady) {
      goToPage(Page.home, null, { callback: router?.asPath });
      handleGoToLoginModal();
    }
    if (
      process.browser &&
      provider &&
      !provider?.approved &&
      !unsecure &&
      router?.isReady &&
      router?.asPath !== Page.perfil
    ) {
      goToPage(Page.perfil, null, { callback: router?.asPath });
    }
  }, [token, router, provider?.approved]);

  if (process.browser && !token && !unsecure && router?.isReady) return null;

  return (
    <div className={styles.container}>
      {provider?.documentNumber && <ClarityTag tag="userId" value={provider?.documentNumber} />}
      <Header />
      <main className={clsx(styles.main, clipContent && styles.clipContent)}>{children}</main>
      <footer>
        <Footer />
      </footer>
      {isLoggedIn && providerHasBoschCarServiceBuyersGroup && (
        <>
          <CouponBoschDiscountModal
            isOpen={isModalOpen}
            cancelHandler={() => setIsModalOpen(false)}
            couponString={process.env.BOSCH_CAR_SERVICE_DISCOUNT_COUPON || 'BOSCH15'}
          />
          <CouponBoschButton openCloseModal={() => setIsModalOpen(!isModalOpen)} />
        </>
      )}
    </div>
  );
};

export { MainLayout };

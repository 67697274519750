import { useState } from 'react';
import {getMediaPath} from '~/utils/getPublicPath';
import styles from './CouponBoschButton.module.scss'

type couponBoschButtonProps = {
  openCloseModal: () => void;
  couponIconOnHover?: string;
  couponIcon?: string;
}

const CouponBoschButton: React.FC<couponBoschButtonProps> = ({ openCloseModal,  }) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const couponIcon = getMediaPath('/media/home/couponIcon.svg');
  const couponIconOnHover = getMediaPath('/media/home/couponIconOnHover.svg');

  return (
    <>
      {isHovering ? (
        <img
          id="couponButton"
          src={couponIconOnHover}
          alt="Botão para abrir/fechar modal de cupom de desconto"
          onMouseOver={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={openCloseModal}
          className={styles['couponButtonImageHover']}
        />
      ) : (
        <img
          id="couponButton"
          src={couponIcon}
          alt="Botão para abrir/fechar modal de cupom de desconto"
          onMouseOver={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={openCloseModal}
          className={styles['couponButtonImage']}
        />
      )}
    </>
  )
}

export {CouponBoschButton};


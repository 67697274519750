import { useReactiveVar } from '@apollo/client';
import { ISbStoryData } from '@storyblok/react';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import { CouponBoschButton, CouponBoschDiscountModal } from '~/components/profile/CouponBoschDiscountModal';
import { DynamicComponent } from '~/components/structure';
import { userLoggedIn } from '~/state/user';
import { Page, useNavigation } from '~/utils/navigation';
import { useAlerts } from '~/utils/useAlerts';
import styles from './StoryLayout.module.scss';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

export interface StoryLayoutProps {
  /** Allow access to a page using this interface w/o being logged in */
  unsecure?: Boolean;
  story: ISbStoryData;
}

export const StoryLayout: React.FC<StoryLayoutProps> = ({ children, story, unsecure = false }) => {
  const { provider, token, isLoggedIn } = useContext(AuthenticationContext);
  const [isCouponBoschDiscountModalOpen, setIsCouponBoschDiscountModalOpen] = useState<boolean>(false);
  const userJustLoggedIn = useReactiveVar(userLoggedIn);

  const providerHasBoschCarServiceBuyersGroup = !!provider?.buyersGroups?.find((item) => item === 'bosch_car_service');

  const { goToPage } = useNavigation();
  const router = useRouter();
  const { actions } = useAlerts();

  const { handleGoToLoginModal } = useLoginAndRegisterModal();

  useEffect(() => {
    if (isLoggedIn && providerHasBoschCarServiceBuyersGroup && userJustLoggedIn) {
      setIsCouponBoschDiscountModalOpen(true);
      userLoggedIn(false);
    }
  }, [isLoggedIn, providerHasBoschCarServiceBuyersGroup, userJustLoggedIn]);

  useEffect(() => {
    if (provider && !provider.isBToBEnabled && !unsecure) {
      actions.addDangerAlert(
        'Você ainda não tem acesso a está área. Por favor, entre em contato com a nossa equipe de atendimento. Você encontra essas informações em peca.ai/ajuda'
      );
      goToPage(Page.home);
      return;
    }
  }, [router?.pathname, provider]);
  // If user is not logged (or it's not an unsecure page), move user to login page
  useEffect(() => {
    if (process.browser && !token && !unsecure && router?.isReady) {
      goToPage(Page.home, null, { callback: router?.asPath });
      handleGoToLoginModal();
    }
    if (
      process.browser &&
      provider &&
      !provider?.approved &&
      !unsecure &&
      router?.isReady &&
      router?.asPath !== Page.perfil
    ) {
      goToPage(Page.perfil, null, { callback: router?.asPath });
    }
  }, [token, router, provider?.approved]);

  if (process.browser && !token && !unsecure && router?.isReady) return null;

  return (
    <div className={styles.container}>
      <DynamicComponent blok={story.content.Header?.content} noWrapper />
      <main className={styles.main}>{children}</main>
      <footer>
        <DynamicComponent blok={story.content.Footer?.content} noWrapper />
      </footer>
      {isLoggedIn && providerHasBoschCarServiceBuyersGroup && (
        <>
          <CouponBoschDiscountModal
            isOpen={isCouponBoschDiscountModalOpen}
            cancelHandler={() => setIsCouponBoschDiscountModalOpen(false)}
            couponString={process.env.BOSCH_CAR_SERVICE_DISCOUNT_COUPON || 'BOSCH15'}
          />
          <CouponBoschButton
            openCloseModal={() => setIsCouponBoschDiscountModalOpen(!isCouponBoschDiscountModalOpen)}
          />
        </>
      )}
    </div>
  );
};

export default StoryLayout;

import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import { CouponBoschButton, CouponBoschDiscountModal } from '~/components/profile/CouponBoschDiscountModal';
import { Footer, Header } from '~/components/structure';
import { useCart } from '~/utils/cart';
import { Page, useNavigation } from '~/utils/navigation';
import { useAlerts } from '~/utils/useAlerts';
import styles from './CheckoutLayout.module.scss';

interface CheckoutLayoutProps {
  className?: string;
}

export const CheckoutLayout: React.FC<CheckoutLayoutProps> = ({ children, className }) => {
  const { provider, isLoggedIn } = useContext(AuthenticationContext);
  const { actions } = useAlerts();
  const { goToPage } = useNavigation();
  const { cart } = useCart();
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const providerHasBoschCarServiceBuyersGroup = !!provider?.buyersGroups?.find((item) => item === 'bosch_car_service');

  useEffect(() => {
    if (provider && (!provider?.isBToBEnabled || !provider?.approved)) {
      actions.addDangerAlert(
        'Você ainda não tem acesso a está área. Por favor, entre em contato com a nossa equipe de atendimento. Você encontra essas informações em peca.ai/ajuda'
      );
      goToPage(Page.home);
      return;
    }
  }, [router?.pathname, provider]);

  // Check if cart is empty and redirect user to home if so
  useEffect(() => {
    if (cart && !cart?.items?.length && !router?.pathname?.includes('confirmacao')) {
      actions.addWarningAlert('Você não tem produtos no carrinho. Explore o catálogo da Peça aí');
      goToPage(Page.home);
    }
  }, [cart]);

  return (
    <>
      <Header minimalist stepper />
      <main className={clsx(styles.main, className)}>{children}</main>
      <footer>
        <Footer />
      </footer>
      {isLoggedIn && providerHasBoschCarServiceBuyersGroup && (
        <>
          <CouponBoschDiscountModal
            isOpen={isModalOpen}
            cancelHandler={() => setIsModalOpen(false)}
            couponString={process.env.BOSCH_CAR_SERVICE_DISCOUNT_COUPON || 'BOSCH15'}
          />
          <CouponBoschButton openCloseModal={() => setIsModalOpen(!isModalOpen)} />
        </>
      )}
    </>
  );
};

export default CheckoutLayout;

import Script from 'next/script';

const ENABLED = !!process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID;

type ClarityCustomTagProps = {
  tag: string;
  value: string;
};

export const ClarityTag: React.FC<ClarityCustomTagProps> = ({ tag, value }) => {
  return ENABLED ? (
    <Script
      id={`ms_${tag}_${value}`}
      strategy="afterInteractive"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `clarity("set", "${tag}", "${value}");`,
      }}
    />
  ) : null;
};
